import MainLayout from "../../layouts/MainLayout";
import { Form, Formik } from "formik";
import * as Yup from "yup";
import "./Register.scss";
import { useEffect, useRef, useState } from "react";
import API from "../../api";
import { ERROR_IDS } from "../../api/utils";
import { useNavigate, useLocation } from "react-router-dom";
import { LANGUAGE, LANGUAGES, ROUTES, STATES } from "../../lib/consts";
import store from "../../store/store";
import { setAccessToken, setRewards } from "../../store/actions/authAction";
import { useTranslation } from "react-i18next";
import { MODAL_TYPES, useGlobalModalContext } from "../../helpers/GlobalModal";
import { useAuthentication } from "../../hooks/useAuthentication";
import {
  addCaptchaScript,
  hideCaptchaBadge,
  showCaptchaBadge,
} from "../../lib/utils";
import { setUserIdentification } from "../../store/actions/authAction";
import { gtagTrackEvent, GA_EVENTS } from "../../lib/utils";
import arrow from "../../assets/images/arrow.svg";
import batchcodeimg from "../../assets/images/batch-code.png";
import close from "../../assets/images/popclose.png";
import batchcodeimage from "../../assets/images/Batch Code Reference.png";
import { log } from "console";
declare var grecaptcha: any;
// declare const turnstile: any;

type StateResponseProps = {
  state: string;
};

const hardcodedStates = [
  { state: "West Bengal" },
  { state: "Uttarakhand" },
  { state: "Uttar Pradesh" },
  { state: "Tripura" },
  { state: "Telangana" },
  { state: "Sikkim" },
  { state: "Rajasthan" },
  { state: "Punjab" },
  { state: "Odisha" },
  { state: "Nagaland" },
  { state: "Mizoram" },
  { state: "Meghalaya" },
  { state: "Manipur" },
  { state: "Maharashtra" },
  { state: "Madhya Pradesh" },
  { state: "Lakshadweep" },
  { state: "Ladakh" },
  { state: "Kerala" },
  { state: "Karnataka" },
  { state: "Jharkhand" },
  { state: "Jammu and Kashmir" },
  { state: "Himachal Pradesh" },
  { state: "Haryana" },
  { state: "Gujarat" },
  { state: "Goa" },
  { state: "Delhi" },
  { state: "Dadra and Nagar Haveli and Daman and Diu" },
  { state: "Chhattisgarh" },
  { state: "Chandigarh" },
  { state: "Bihar" },
  { state: "Assam" },
  { state: "Arunachal Pradesh" },
  { state: "Andhra Pradesh" },
  { state: "Andaman and Nicobar Islands" },
  // Add more states as needed
];

const Register = () => {
  const [isAgreed, setIsAgreed] = useState(false);
  const [showError, setShowError] = useState(false);
  const [checkboxError, setCheckboxError] = useState("");
  const { showModal } = useGlobalModalContext();
  const [resendTimer, setResendTimer] = useState(60);
  const [showOtpForm, setShowOtpForm] = useState(false);
  const [showPayoutForm, setShowPayoutForm] = useState(false);
  const [showStateDropdown, setShowStateDropdown] = useState(false);
  const [filteredCities, setFilteredCities] = useState<StateResponseProps[]>(
    []
  );
  const [filteredNewStates, setFilteredNewStates] = useState<
    StateResponseProps[]
  >([]);
  const [citiesFound, setCitiesFound] = useState(true);
  const [states, setStates] = useState("");
  const [selectedState, setSelectedState] = useState("");

  const [showPopup, setShowPopup] = useState(false);
//   const [cloudFareToken, setCloudFareToken] = useState("");
// const widgetId = useRef<any>()
  const handleClick = () => {
    setShowPopup(true);
  };

  const handleClosePopup = () => {
    setShowPopup(false);
  };

  const handleStateFieldClick = () => {
    setShowStateDropdown(!showStateDropdown);
  };

  const { isLoggedIn } = useAuthentication();
  const navigate = useNavigate();
  const location = useLocation();

  const timer = useRef<any | null>(null);

  const { t } = useTranslation();
  const handleCheckboxChange = () => {
    setIsAgreed(!isAgreed);
    setCheckboxError("");
  };

  useEffect(() => {
    setFilteredNewStates(filteredCities);
  }, [filteredCities]);

  useEffect(() => {
    // console.log(filteredCities);
    // console.log(filteredNewStates);
    {
      setFilteredCities(hardcodedStates);
    }
  }, []);

  useEffect(() => {
    // console.log("Show State Dropdown:", showStateDropdown);
  }, [showStateDropdown]);

  //State Dropdown:
  const handleStateChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const inputValue = event.target.value.toLowerCase();

    const filtered = filteredCities.filter((state) =>
      state.state.toLowerCase().includes(inputValue)
    );

    setCitiesFound(filtered.length > 0);

    setFilteredNewStates(filtered);
    setShowStateDropdown(true);
  };

  const handleStateSelect = (selectedState: string) => {
    // console.log("modal clicke", selectedState);

    setStates(selectedState);
    setSelectedState(selectedState);
    // console.log("states", states);
    setShowStateDropdown(false);
  };

  const RegisterValidation = Yup.object().shape({
    name: Yup.string()
      .required(t("registerPage.errors.invalidName") || "Enter valid name")
      .matches(
        /^[a-zA-Z]{1}[a-zA-Z\s]{2,}$/,
        t("registerPage.errors.invalidName") || "Enter valid name"
      ),
    mobile: Yup.string()
      .required(
        t("registerPage.errors.invalidMobile") ||
          "*Please enter a valid 10-digit number"
      )
      .matches(
        /^[56789][0-9]{9}$/,
        t("registerPage.errors.invalidMobile") ||
          "*Please enter a valid 10-digit number"
      ),
    code: Yup.string().required(
      t("registerPage.errors.invalidCode") ||
        "*That’s not a valid code. Please check and enter the correct code"
    ),
    state: Yup.string().required(
      t("registerPage.errors.invalidstate") || "*Please enter a valid State"
    ),

    agreeTnc: Yup.boolean().oneOf(
      [true],
      "Please accept the terms and conditions"
    ),
  });

  const OtpValidation = Yup.object().shape({
    otp: Yup.string()
      .required(
        t("registerPage.errors.invalidOtp") || "*Please enter valid OTP"
      )
      .matches(
        /^[0-9]{6}$/,
        t("registerPage.errors.incorrectOtp") ||
          "*You have entered an incorrect OTP. Please try again"
      ),
  });

  // useEffect(() => {
  //   addCaptchaScript(() => {
  //     showCaptchaBadge();
  //   }); // Add this line to include the reCAPTCHA script
  //   return () => {
  //     hideCaptchaBadge(); // Add this line to hide the reCAPTCHA badge when the component is unmounted
  //   };
  // }, []);

  const startTimer = () => {
    if (timer) clearInterval(timer.current);
    let time = 60;
    setResendTimer(time);
    timer.current = setInterval(() => {
      time--;
      setResendTimer(time);
      if (time <= 0) {
        clearInterval(timer.current);
      }
    }, 1000);
  };

//   useEffect(() => {
//     if (typeof turnstile !== "undefined" && showOtpForm) {
//       console.log("key:" , process.env.REACT_APP_CLOUDFARE_SITE_KEY)
//         turnstile.ready(() => {
//             if (!widgetId.current) {
//                 widgetId.current = turnstile.render("#cf-turnstile-otp", {
//                     sitekey: process.env.REACT_APP_CLOUDFARE_SITE_KEY,
//                     theme: "light",
//                     callback: (token: string) => {
//                         setCloudFareToken(token)
//                         console.log("token", token)
//                     },
//                 });
//             }
//         });
//     } else {
//         console.error("Turnstile script not loaded.");
//     }
// }, [showOtpForm]);


  const resendOtp = () => {
    if (resendTimer <= 0) {
      startTimer();
      API.resendOTP().catch((err) => {
        console.log(err);
      });
    }
  };

  useEffect(
    () => () => {
      if (timer.current) {
        clearInterval(timer.current);
      }
    },
    []
  );

  function renderRegisterForm() {
    return (
      <Formik
        key="register-form"
        initialValues={{
          name: "",
          mobile: "",
          code: "",
          email: "",
          state: states,
          agreeTnc: false,
        }}
        validationSchema={RegisterValidation}
        onSubmit={(values, errors) => {
          console.log(errors, values);
          // setShowOtpForm(true);
          if (values.agreeTnc) {
            API.register(values).then(() => {
              gtagTrackEvent(GA_EVENTS.Registered);
              setShowOtpForm(true);
              startTimer();
            });
          } else {
            setCheckboxError("Please accept the terms and conditions");
            setShowError(true); // Optionally, show an error message
          }
          gtagTrackEvent(GA_EVENTS.Submit_click);
        }}
      >
        {({
          values,
          handleChange,
          handleSubmit,
          handleBlur,
          errors,
          touched,
          setFieldValue,
        }) => {
          return (
            <Form
              onSubmit={handleSubmit}
              onClick={() => setShowStateDropdown(false)}
            >
              <div className="register-form">
                <p className="form-title "></p>
                Thank you for your interest,<br/>
                            this contest is now closed
              </div>
              {/*<p className="coming-soon">COMING SOON</p>*/}
            </Form>
          );
        }}
      </Formik>
    );
  }

  function renderOtpForm() {






    
    return (
      <Formik
        key="otp-form"
        initialValues={{
          otp: "",
        }}
        validationSchema={OtpValidation}
        onSubmit={(values, errors) => {
          // navigate(ROUTES.COMPLETED);

          // grecaptcha
          //   .execute(process.env.REACT_APP_CAPTCHA_SITE_KEY, {
          //     action: "verify_otp",
          //   })
          //   .then((token: string) => {
            // if (cloudFareToken) {
              // API.verifyOTP(values.otp,cloudFareToken)
              API.verifyOTP(values.otp)
                .then((response) => {
                  store.dispatch(setAccessToken(response.accessToken));

                  hideCaptchaBadge();
                  // debugger;
                  
                  store.dispatch(setRewards({myGlamCode: response.myGlamCode,
                    isCashbackWinner: response.isCashbackWinner,
                    cashbackRewardCode: response.cashbackRewardCode}));
                  gtagTrackEvent(GA_EVENTS.Verify_OTP);
                  if (response.fromPuducherry === true) {
                    navigate(ROUTES.THANK_YOU);
                  } else {
                    navigate(ROUTES.COMPLETED);
                  }
                })
                .catch((err) => {
                  const { messageId } = err;
                  switch (messageId) {
                    case ERROR_IDS.INVALID_OTP:
                      errors.setErrors({
                        otp:
                          t("registerPage.errors.invalidOtp") ||
                          "Please enter a valid OTP",
                      });
                      break;
                    default:
                      errors.setErrors({
                        otp: err.message,
                      });
                      break;
                  }
                });
            //   } else {
            //     errors.setErrors({
            //         otp: "Please complete captcha verification!",
            //     });
            // }
            // });
        }}
      >
        {({
          values,
          handleChange,
          handleSubmit,
          handleBlur,
          errors,
          touched,
        }) => {
          return (
            <Form onSubmit={handleSubmit}>
              <div className="otp-form">
                <p className="form-title">Enter OTP</p>
                <div className="input-group">
                  <input
                    autoComplete="off"
                    type="tel"
                    onChange={handleChange}
                    value={values.otp}
                    name="otp"
                    maxLength={6}
                    onBlur={handleBlur}
                    placeholder={t("registerPage.placeholder.otp")}
                  />
                </div>
                {errors.otp && touched.otp && (
                  <p className="error">{errors.otp}</p>
                )}
                <div className="flex-center timer-wrapper">
                  {resendTimer > 0 && (
                    <div className="timer flex-center">{resendTimer}</div>
                  )}
                  <span>{t("registerPage.didNotReceive")}</span>
                  <span className="link" onClick={resendOtp}>
                    {t("registerPage.resend")}
                  </span>
                </div>
              </div>
              {/* <div id="cf-turnstile-otp">
                
              </div>   */}
              <button className="btn btn-primary" type="submit">
              Verify
              </button>
            </Form>
          );
        }}
      </Formik>
    );
  }

  return (
    <MainLayout className="register-page">
      {showOtpForm ? renderOtpForm() : renderRegisterForm()}
    </MainLayout>
  );
};

export default Register;
